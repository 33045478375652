import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useGlobalSettings = () => {
  const DEFAULT_SETTING_ID = 1;
  const toast = useToast();

  const getGlobalSetting = async () => {
    try {
      return await store.dispatch('setting/get', DEFAULT_SETTING_ID);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting global settings',
          icon: 'XCircleIcon',
        },
      });
      return undefined;
    }
  };
  const updateGlobalSetting = async (setting) => {
    try {
      await store.dispatch('setting/update', setting);
      return null;
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating global settings',
          icon: 'XCircleIcon',
        },
      });
      return undefined;
    }
  };

  return {
    // Method
    getGlobalSetting,
    updateGlobalSetting,
  };
};

export default useGlobalSettings;
