var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.settingData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Error fetching settings data")]),_c('div',{staticClass:"alert-body"},[_vm._v("Please try it again.")])]),(_vm.settingData)?[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"globalSettingsForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h3',[_vm._v("GTA Office Percentages")]),_c('b-badge',{staticClass:"profile-badge ml-75",attrs:{"variant":"primary"}},[_vm._v(" MEMBER ")])],1),_c('b-row',{staticClass:"my-1 px-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Airport Pick Up GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Airport Pick Up GTA Commission","label-for":"airport-pick-up-commission","description":"GTA Office Percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"airport-pick-up-commission","type":"number"},model:{value:(
                          _vm.settingData.memberAirportPickUpGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "memberAirportPickUpGtaPercentageCommission", $$v)},expression:"\n                          settingData.memberAirportPickUpGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3569511251)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Baggage GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Baggage GTA Commission","label-for":"baggage-commission","description":"Baggage GTA office commission"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"baggage-commission","type":"number"},model:{value:(
                          _vm.settingData.memberBaggageGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "memberBaggageGtaPercentageCommission", $$v)},expression:"\n                          settingData.memberBaggageGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3953931718)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Meter Reader GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Meter Reader GTA Commission","label-for":"meter-reader-gta-commission","description":"GTA Office Percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"meter-reader-gta-commission","type":"number"},model:{value:(
                          _vm.settingData.memberMeterReaderGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "memberMeterReaderGtaPercentageCommission", $$v)},expression:"\n                          settingData.memberMeterReaderGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1299715731)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Wait Time GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wait Time GTA Commission","label-for":"wait-time-commission","description":"GTA Office Percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"wait-time-commission","type":"number"},model:{value:(
                          _vm.settingData.memberWaitTimeGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "memberWaitTimeGtaPercentageCommission", $$v)},expression:"\n                          settingData.memberWaitTimeGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1028468691)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Wheelchar GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wheelchair GTA Commission","label-for":"wheelchair-gta-commission","description":"GTA Office percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"wheelchair-gta-commission","type":"number"},model:{value:(
                          _vm.settingData.memberWheelchairGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "memberWheelchairGtaPercentageCommission", $$v)},expression:"\n                          settingData.memberWheelchairGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2849828403)})],1)],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('h3',[_vm._v("GTA Office Percentages")]),_c('b-badge',{staticClass:"profile-badge ml-75",attrs:{"variant":"secondary"}},[_vm._v(" NON MEMBER ")])],1),_c('b-row',{staticClass:"my-1 px-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Airport Pick Up GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Airport Pick Up GTA Commission","label-for":"non-member-airport-pick-up-commission","description":"GTA Office Percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"non-member-airport-pick-up-commission","type":"number"},model:{value:(
                          _vm.settingData.nonMemberAirportPickUpGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "nonMemberAirportPickUpGtaPercentageCommission", $$v)},expression:"\n                          settingData.nonMemberAirportPickUpGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,799027292)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Baggage GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Baggage GTA Commission","label-for":"non-member-baggage-commission","description":"Baggage GTA office commission"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"non-member-baggage-commission","type":"number"},model:{value:(
                          _vm.settingData.nonMemberBaggageGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "nonMemberBaggageGtaPercentageCommission", $$v)},expression:"\n                          settingData.nonMemberBaggageGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,542771337)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Meter Reader GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Meter Reader GTA Commission","label-for":"non-member-meter-reader-gta-commission","description":"GTA Office Percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"non-member-meter-reader-gta-commission","type":"number"},model:{value:(
                          _vm.settingData.nonMemberMeterReaderGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "nonMemberMeterReaderGtaPercentageCommission", $$v)},expression:"\n                          settingData.nonMemberMeterReaderGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3547794044)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Wait Time GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wait Time GTA Commission","label-for":"non-member-wait-time-commission","description":"GTA Office Percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"non-member-wait-time-commission","type":"number"},model:{value:(
                          _vm.settingData.nonMemberWaitTimeGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "nonMemberWaitTimeGtaPercentageCommission", $$v)},expression:"\n                          settingData.nonMemberWaitTimeGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3421313148)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Wheelchar GTA Commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wheelchair GTA Commission","label-for":"non-member-wheelchair-gta-commission","description":"GTA Office percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"non-member-wheelchair-gta-commission","type":"number"},model:{value:(
                          _vm.settingData.nonMemberWheelchairGtaPercentageCommission
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "nonMemberWheelchairGtaPercentageCommission", $$v)},expression:"\n                          settingData.nonMemberWheelchairGtaPercentageCommission\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4275242012)})],1)],1),_c('h3',[_vm._v("Aiport Pick Up Prices")]),_c('b-row',{staticClass:"my-1 px-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Airport Pick Up Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Airport Pick Up Price","label-for":"airport-pick-up","description":("Airport Pick Up Price for " + (_vm.settingData.airportPickUpMaxPaxToApplyRateExtra) + " passenger")}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"airport-pick-up","type":"number","step":".01"},model:{value:(_vm.settingData.airportPickUpRate),callback:function ($$v) {_vm.$set(_vm.settingData, "airportPickUpRate", $$v)},expression:"settingData.airportPickUpRate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1418321711)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Number of Passenger Without Extra Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Number of Passenger Without Extra Price","label-for":"number-passengers-without-extra-price","description":"Maximun number of passengers without extra price"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UsersIcon"}})],1),_c('b-form-input',{attrs:{"id":"number-passengers-without-extra-price","type":"number"},model:{value:(
                          _vm.settingData.airportPickUpMaxPaxToApplyRateExtra
                        ),callback:function ($$v) {_vm.$set(_vm.settingData, "airportPickUpMaxPaxToApplyRateExtra", $$v)},expression:"\n                          settingData.airportPickUpMaxPaxToApplyRateExtra\n                        "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3994034625)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Airport Pick Up Price Extra Passenger","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Airport Pick Up Price Extra Passenger","label-for":"airport-pick-up-price-extra-passenger","description":"Airport Pick Up Price per extra passenger"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"airport-pick-up-price-extra-passenger","type":"number","step":".01"},model:{value:(_vm.settingData.airportPickUpRateExtraPassenger),callback:function ($$v) {_vm.$set(_vm.settingData, "airportPickUpRateExtraPassenger", $$v)},expression:"settingData.airportPickUpRateExtraPassenger"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1855634558)})],1)],1),_c('h3',[_vm._v("Others Settings")]),_c('b-row',{staticClass:"my-1 px-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Price per  Baggage Kilo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Price per Baggage Kilo","label-for":"price-pre-baggage-kilo","description":"Price per baggage kilo"}},[_c('b-input-group',{staticClass:"input-group-merge invoice-edit-input-group"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"price-pre-baggage-kilo","type":"number","step":".01"},model:{value:(_vm.settingData.baggagePerKiloRate),callback:function ($$v) {_vm.$set(_vm.settingData, "baggagePerKiloRate", $$v)},expression:"settingData.baggagePerKiloRate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1403161134)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Max Crew Passenger","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Max Crew Passenger","label-for":"max-passenger","description":"Max crew passenger per taxi"}},[_c('b-input-group',{staticClass:"input-group-merge invoice-edit-input-group"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UsersIcon"}})],1),_c('b-form-input',{attrs:{"id":"max-pasenger","type":"number"},model:{value:(_vm.settingData.maxCrewPassengersPerTaxi),callback:function ($$v) {_vm.$set(_vm.settingData, "maxCrewPassengersPerTaxi", $$v)},expression:"settingData.maxCrewPassengersPerTaxi"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3026215280)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Price per PAX passenger","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Price per PAX passenger","label-for":"pax-price","description":"Price per PAX passenger"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"pax-price","type":"number","step":".01"},model:{value:(_vm.settingData.pricePerPax),callback:function ($$v) {_vm.$set(_vm.settingData, "pricePerPax", $$v)},expression:"settingData.pricePerPax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,888172735)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Environment Ticket Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Environment Ticket Price","label-for":"environment-ticket-price","description":"Price per Environment Ticket"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"environment-ticket-price","type":"number","step":".01"},model:{value:(_vm.settingData.pricePerEnvironmentTicket),callback:function ($$v) {_vm.$set(_vm.settingData, "pricePerEnvironmentTicket", $$v)},expression:"settingData.pricePerEnvironmentTicket"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4095510592)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Wheelchair Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wheelchair Rate","label-for":"wheelchair-rate","description":"Rate per wheelchair"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"wheelchair-rate","type":"number","step":".01"},model:{value:(_vm.settingData.wheelchairRate),callback:function ($$v) {_vm.$set(_vm.settingData, "wheelchairRate", $$v)},expression:"settingData.wheelchairRate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2172042139)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Rebate Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rebate Rate","label-for":"rebate-rate","description":"Rebate rate percentage"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{attrs:{"id":"rebate-rate","type":"number","step":"1"},model:{value:(_vm.settingData.rebateFee),callback:function ($$v) {_vm.$set(_vm.settingData, "rebateFee", $$v)},expression:"settingData.rebateFee"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,919431615)})],1)],1),_c('div',{staticClass:"w-100 d-flex justify-content-end pr-2"},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" Edit ")],1)],1)],1)],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }