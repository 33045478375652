<template>
  <div>
    <b-alert
      variant="danger"
      :show="settingData === undefined"
    >
      <h4 class="alert-heading">Error fetching settings data</h4>
      <div class="alert-body">Please try it again.</div>
    </b-alert>

    <template v-if="settingData">
      <b-card no-body>
        <b-card-body>
          <validation-observer ref="globalSettingsForm">
            <b-form @submit.prevent="validateForm()">
              <div class="d-flex align-items-center">
                <h3>GTA Office Percentages</h3>
                <b-badge
                  variant="primary"
                  class="profile-badge ml-75"
                >
                  MEMBER
                </b-badge>
              </div>
              <b-row class="my-1 px-2">
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Airport Pick Up GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Airport Pick Up GTA Commission"
                      label-for="airport-pick-up-commission"
                      description="GTA Office Percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.memberAirportPickUpGtaPercentageCommission
                          "
                          id="airport-pick-up-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Baggage GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Baggage GTA Commission"
                      label-for="baggage-commission"
                      description="Baggage GTA office commission"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.memberBaggageGtaPercentageCommission
                          "
                          id="baggage-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Meter Reader GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Meter Reader GTA Commission"
                      label-for="meter-reader-gta-commission"
                      description="GTA Office Percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.memberMeterReaderGtaPercentageCommission
                          "
                          id="meter-reader-gta-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Wait Time GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Wait Time GTA Commission"
                      label-for="wait-time-commission"
                      description="GTA Office Percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.memberWaitTimeGtaPercentageCommission
                          "
                          id="wait-time-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Wheelchar GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Wheelchair GTA Commission"
                      label-for="wheelchair-gta-commission"
                      description="GTA Office percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.memberWheelchairGtaPercentageCommission
                          "
                          id="wheelchair-gta-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="d-flex align-items-center">
                <h3>GTA Office Percentages</h3>
                <b-badge
                  variant="secondary"
                  class="profile-badge ml-75"
                >
                  NON MEMBER
                </b-badge>
              </div>
              <b-row class="my-1 px-2">
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Airport Pick Up GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Airport Pick Up GTA Commission"
                      label-for="non-member-airport-pick-up-commission"
                      description="GTA Office Percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.nonMemberAirportPickUpGtaPercentageCommission
                          "
                          id="non-member-airport-pick-up-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Baggage GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Baggage GTA Commission"
                      label-for="non-member-baggage-commission"
                      description="Baggage GTA office commission"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.nonMemberBaggageGtaPercentageCommission
                          "
                          id="non-member-baggage-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Meter Reader GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Meter Reader GTA Commission"
                      label-for="non-member-meter-reader-gta-commission"
                      description="GTA Office Percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.nonMemberMeterReaderGtaPercentageCommission
                          "
                          id="non-member-meter-reader-gta-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Wait Time GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Wait Time GTA Commission"
                      label-for="non-member-wait-time-commission"
                      description="GTA Office Percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.nonMemberWaitTimeGtaPercentageCommission
                          "
                          id="non-member-wait-time-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Wheelchar GTA Commission"
                    rules="required"
                  >
                    <b-form-group
                      label="Wheelchair GTA Commission"
                      label-for="non-member-wheelchair-gta-commission"
                      description="GTA Office percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="
                            settingData.nonMemberWheelchairGtaPercentageCommission
                          "
                          id="non-member-wheelchair-gta-commission"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <h3>Aiport Pick Up Prices</h3>
              <b-row class="my-1 px-2">
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Airport Pick Up Price"
                    rules="required"
                  >
                    <b-form-group
                      label="Airport Pick Up Price"
                      label-for="airport-pick-up"
                      :description="`Airport Pick Up Price for ${settingData.airportPickUpMaxPaxToApplyRateExtra} passenger`"
                    >
                      <b-input-group
                        prepend="£"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="settingData.airportPickUpRate"
                          id="airport-pick-up"
                          type="number"
                          step=".01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Number of Passenger Without Extra Price"
                    rules="required"
                  >
                    <b-form-group
                      label="Number of Passenger Without Extra Price"
                      label-for="number-passengers-without-extra-price"
                      description="Maximun number of passengers without extra price"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UsersIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          v-model="
                            settingData.airportPickUpMaxPaxToApplyRateExtra
                          "
                          id="number-passengers-without-extra-price"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Airport Pick Up Price Extra Passenger"
                    rules="required"
                  >
                    <b-form-group
                      label="Airport Pick Up Price Extra Passenger"
                      label-for="airport-pick-up-price-extra-passenger"
                      description="Airport Pick Up Price per extra passenger"
                    >
                      <b-input-group
                        prepend="£"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="settingData.airportPickUpRateExtraPassenger"
                          id="airport-pick-up-price-extra-passenger"
                          type="number"
                          step=".01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <h3>Others Settings</h3>
              <b-row class="my-1 px-2">
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Price per  Baggage Kilo"
                    rules="required"
                  >
                    <b-form-group
                      label="Price per Baggage Kilo"
                      label-for="price-pre-baggage-kilo"
                      description="Price per baggage kilo"
                    >
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="BriefcaseIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="settingData.baggagePerKiloRate"
                          id="price-pre-baggage-kilo"
                          type="number"
                          step=".01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Max Crew Passenger"
                    rules="required"
                  >
                    <b-form-group
                      label="Max Crew Passenger"
                      label-for="max-passenger"
                      description="Max crew passenger per taxi"
                    >
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UsersIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="settingData.maxCrewPassengersPerTaxi"
                          id="max-pasenger"
                          type="number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Price per PAX passenger"
                    rules="required"
                  >
                    <b-form-group
                      label="Price per PAX passenger"
                      label-for="pax-price"
                      description="Price per PAX passenger"
                    >
                      <b-input-group
                        prepend="£"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="settingData.pricePerPax"
                          id="pax-price"
                          type="number"
                          step=".01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Environment Ticket Price"
                    rules="required"
                  >
                    <b-form-group
                      label="Environment Ticket Price"
                      label-for="environment-ticket-price"
                      description="Price per Environment Ticket"
                    >
                      <b-input-group
                        prepend="£"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="settingData.pricePerEnvironmentTicket"
                          id="environment-ticket-price"
                          type="number"
                          step=".01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Wheelchair Rate"
                    rules="required"
                  >
                    <b-form-group
                      label="Wheelchair Rate"
                      label-for="wheelchair-rate"
                      description="Rate per wheelchair"
                    >
                      <b-input-group
                        prepend="£"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="settingData.wheelchairRate"
                          id="wheelchair-rate"
                          type="number"
                          step=".01"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Rebate Rate"
                    rules="required"
                  >
                    <b-form-group
                      label="Rebate Rate"
                      label-for="rebate-rate"
                      description="Rebate rate percentage"
                    >
                      <b-input-group
                        prepend="%"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="settingData.rebateFee"
                          id="rebate-rate"
                          type="number"
                          step="1"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="w-100 d-flex justify-content-end pr-2">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="isLoading"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                    variant="light"
                  />
                  Edit
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useGlobalSettings from '@/modules/setting/composables/useGlobalSettings';

export default {
  setup() {
    const toast = useToast();
    const { getGlobalSetting, updateGlobalSetting } = useGlobalSettings();

    const globalSettingsForm = ref(null);
    const settingData = ref(null);
    const isLoading = ref(false);

    const validateForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await globalSettingsForm.value.validate();

      if (!valid) {
        isLoading.value = false;
        return;
      }

      const response = await updateGlobalSetting(settingData.value);
      if (response !== undefined) {
        toast.success({
          component: ToastificationContent,
          props: {
            title: 'Settigs has been edited successfully!',
            icon: 'CheckIcon',
          },
        });
      }

      isLoading.value = false;
    };

    getGlobalSetting().then((setting) => {
      settingData.value = setting;
    });

    return {
      // Reactive
      globalSettingsForm,
      settingData,
      isLoading,

      // Method
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
};
</script>
